import React from 'react';
import DirworkComponent from './DirworkComponent'


function App() {
  return (
    <>
	<DirworkComponent/>
    </>
  );
}

export default App;
